<template>
  <div style="position: relative">
    <div class="heads">
      <el-steps :active="recorItem.step" align-center>
        <el-step title="资质审核"></el-step>
        <el-step title="经营范围设置"></el-step>
        <el-step title="完成"></el-step>
      </el-steps>
    </div>
    <div class="merlends" style="min-height: 80vh">
      <div v-show="recorItem.step == 1 || recorItem.step == 0">
        <RecordDetails
          ref="Recordes"
          :Editreview="true"
          :recorItem="recorItem"
        />
        <div class="btn-box">
          <div class="go-back" @click="$router.push({ path: '/goodsAudit' })">
            返回
          </div>
          <div class="refuse" @click="toRef">申请驳回</div>
          <div class="pass" @click="toNext">通过并进入下一步</div>
        </div>
      </div>
      <div v-show="recorItem.step == 2">
        <QualifiCategory ref="Qualifies" :recorItem="recorItem" />
        <div class="btn-box">
          <div class="go-back" @click="$router.push({ path: '/goodsAudit' })">
            返回
          </div>
          <div class="refuse" @click="recorItem.step = 1">上一步</div>
          <div class="pass" @click="accomplish">审核通过</div>
        </div>
      </div>
      <div
        v-show="
          recorItem.step == 3 || recorItem.step == 4 || recorItem.step == 5
        "
      >
        <NotPass :recorItem="recorItem" />
      </div>
    </div>
    <!-- 拒绝弹框 -->
    <el-dialog title="审核驳回提醒" :visible.sync="isRefuse" width="600px">
      <div style="margin-bottom: 20px;">
        确定驳回当前供应商资质审核？如果当前供应商资质证照不符合平台要求，请在驳回原因中注明。
      </div>
      <div class="fx">
        <div style="width:100px">驳回原因：</div>
        <el-input
          type="textarea"
          :rows="7"
          placeholder="请输入内容"
          v-model="AuditMessage.remark"
          maxlength="200"
          show-word-limit
        >
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isRefuse = false">取 消</el-button>
        <el-button type="primary" @click="notarizeRefuse">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("Audit"); //vuex公共库库
const merchantCenterList = createNamespacedHelpers("merchantCenterList");
const Audit = createNamespacedHelpers("Audit");
import RecordDetails from "@/components/RecordDetails/RecordDetails.vue";
import QualifiCategory from "@/components/QualifiCategory/QualifiCategory.vue";
import NotPass from "./goodsMess/notPass.vue";
export default {
  name: "promptlyAudit",
  components: {
    RecordDetails,
    QualifiCategory,
    NotPass,
  },
  data() {
    return {
      isRefuse: false,
      AuditMessage: {
        remark: "", //拒绝原因
      },
      recorItem: {
        step: 1,
        examineBaseInfo: {}, //企业基础信息
        qualification: [], //资质数据
        companyTypeInfo: [], //企业类型数据
        scopeInfo: [], //经营范围数据
      },
    };
  },
  created() {
    this.getItem();
  },
  methods: {
    ...mapActions([
      "postreviewstep", //记录进度、拒绝
      "getreviewInfo", //通过审核
    ]),
    ...merchantCenterList.mapActions([
      "getCredentials", //审核记录详情（资质详情）
    ]),
    ...Audit.mapActions([
      "postscopeConfig", //保存经营范围
    ]),
    /** 获取详情数据 */
    getItem() {
      this.getCredentials({
        businessId: this.$route.query.businessId,
        auditSerialNumber: this.$route.query.auditSerialNumber,
        isBusinessInfo: this.$route.query.isBusinessInfo,
      }).then((res) => {
        Object.assign(this.recorItem, res);
        this.recorItem.step == 0 ? (this.recorItem.step = 1) : "";
        this.$nextTick(() => {
          this.$refs.Recordes.getItem();
        });
      });
    },
    // 拒绝
    toRef() {
      this.AuditMessage = {};
      this.isRefuse = true;
    },
    // 确认拒绝
    async notarizeRefuse() {
      this.AuditMessage.serial_number = this.$route.query.auditSerialNumber;
      this.AuditMessage.step = 5;
      let data = await this.postreviewstep(this.AuditMessage);
      if (data.code == 200) {
        this.$router.push({ path: "/goodsAudit" });
        this.$message.success(data.message);
      } else {
        this.$message.error(data.message);
      }
    },
    // 通过进入下一步
    async toNext() {
      let data = await this.postreviewstep({
        serial_number: this.$route.query.auditSerialNumber,
        step: 2,
      });
      if (data.code == 200) {
        this.$message.success(data.message);
        this.recorItem.step = 2;
        this.$nextTick(() => {
          this.$refs.Qualifies.getItem();
        });
      } else {
        this.$message.error(data.message);
      }
    },
    //审核通过
    async accomplish() {
      let num = this.recorItem.scopeInfo.filter((v) => {
        return v.isSetVal != 2;
      });
      if (num.length == 0) {
        this.$message.error("请选择经营范围");
        return false;
      }
      let notime = num.filter((v) => {
        return v.expirationTime == null || v.expirationTime == "";
      });
      if (notime.length > 0) {
        this.$message.error(`请选择 ${notime[0].businessScopeName}有效期`);
        return false;
      }
      let params = JSON.stringify(num);
      params = JSON.parse(params);
      var dataList = [];
      params.forEach((v) => {
        dataList.push({
          business_scope_id: v.businessScopeId,
          business_id: this.$route.query.businessId,
          expiration_time: v.expirationTime,
          select_id: v.selectId,
        });
      });
      var daItem = {
        auditSerialNumber: this.$route.query.auditSerialNumber,
        scopeInfo: dataList,
      };

      this.$confirm(
        `确定通过当前供应商的资质审核？请您仔细核对供应商信息，以保证供应商资质符合要求且填写无误。`,
        "审核通过提醒",
        {
          closeOnClickModal: false,
          cancelButtonText: "取消",
          confirmButtonText: "审核通过",
        }
      )
        .then(async () => {
          const loading = this.$loading({
            lock: true,
            text: "提交中",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          let data = await this.postscopeConfig(daItem);
          if (data.code == "200") {
            this.$message.success("操作成功");
            let data = await this.postreviewstep({
              serial_number: this.$route.query.auditSerialNumber,
              step: 4,
            });
            if (data.code == "200") {
              await this.getItem();
              loading.close();
              this.$message.success(data.message);
            } else {
              loading.close();
              this.$message.error(data.message);
            }
          }
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang='scss' scoped>
//步骤条
::v-deep .el-steps {
  padding: 13px 15%;
}
::v-deep .el-step.is-simple:not(:last-of-type) .el-step__title {
  width: 60%;
}
::v-deep .el-step__title {
  font-size: 12px;
}
::v-deep .el-step__head.is-process {
  color: #c0c4cc;
  border-color: #c0c4cc;
}
::v-deep .el-step__title.is-process {
  font-weight: 500;
  color: #c0c4cc;
}
.heads {
  position: sticky;
  top: 0px;
  left: 0;
  width: calc(100% + 10px);
  background: rgba(239, 239, 239);
  height: 86px;
  transform: translate(-5px, -10px);
  z-index: 1000;
  margin-top: -10px;
}
.btn-box {
  margin-top: 20px;
  width: 100%;
  height: 32px;
  display: flex;
  align-content: center;
  justify-content: center;
  div {
    font-size: 14px;
    text-align: center;
    border-radius: 4px;
    font-weight: normal;
    line-height: 32px;
    cursor: pointer;
  }
  .go-back {
    width: 88px;
    color: rgba($color: #1c1f23, $alpha: 0.6);
    border: 1px solid #d4d7d9;
  }
  .refuse {
    width: 116px;
    background: #f6b142;
    color: #fff;
    margin: 0 20px;
  }
  .pass {
    width: 156px;
    background: #00bfbf;
    color: #ffffff;
  }
}
</style>